<template>
  <Section v-if="!parcel || !parcel_sheet">
    <Container>
      <p>
        Vous n'avez pas ajouté de parcelle à l'essai.<br />
        Voulez vous ajouter une nouvelle parcelle ?
      </p>
      <div class="grid">
        <div class="tmp-col">
          <Btn v-if="helperService.userHasPermission('parcelle_add')"
            text="Ajouter nouvelle parcelle"
            :to="{ name: 'parcelAdd', query: {from :$route.name , e:$route.params.id} }"
            icon="plus"
            color="default" />
        </div>
        <input type="hidden"
          name="void" />
      </div>

    </Container>
  </Section>

  <ParcelAddEdit v-model="submit"
    v-if="parcel && parcel_sheet"
    :parcel="parcel"
    :parcel_sheet="parcel_sheet"
    @post-data="postData" />
</template>
<script>
import ParcelAddEdit from '@/views/componentsViews/parcel/ParcelAddEdit.vue'
import Btn from '@/components/base/Btn.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'

export default {
  name: 'generalInformationAddEditSubview',
  components: {
    ParcelAddEdit,
    Btn,
    Section,
    Container,
  },
  props: {
    pageTitle: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      submit: false,
      parcel: null,
      parcel_sheet: null,
      previousRouteName: null,
    }
  },
  emits: ['set-step', 'set-data', 'set-route', 'update:modelValue'],
  watch: {
    modelValue() {
      this.submit = this.modelValue
    },
    submit() {
      this.$emit('update:modelValue', this.submit)
      if (!this.parcel_sheet && this.submit) {
        this.submit = false
        this.nextStep()
      }
    },
  },
  mounted() {
    if (!this.$route.name.includes('Edit')) {
      this.previousRouteName = 'essaiGeneralInformationAdd'
    }

    this.$emit('set-route', {
      previousRouteName: this.previousRouteName,
      routeParams: { id: this.$route.params.id },
    })
    this.$emit('set-step', 1)
    this.endPoint = 'essai'
    if (this.$route.params.id) {
      this.method = 'put'
      this.endPoint += `/${this.$route.params.id}`
      this.loadData()
    }
  },
  methods: {
    addParcel() {
      this.emitter.emit('open-loader')
      const values = {}

      values.nom = this.essai.nom
      values.protocole_id = this.essai.protocole.id
      values.parcelle_id = this.$route.query.upd
      this.fetchService.put(`essai/${this.$route.params.id}`, values).then(
        () => {
          this.$router.replace({})
          this.loadData()
        },
        () => {
          this.emitter.emit('close-loader')
        },
      )
    },
    loadData() {
      this.emitter.emit('open-loader')
      this.fetchService
        .get(`essai/${this.$route.params.id}`)
        .then((response) => {
          this.essai = response.data
          this.$emit('set-data', this.essai)
          this.parcel = this.essai.parcelle
          this.parcel_sheet = this.essai.parcelle_fiche

          if (this.$route.query.upd) {
            this.addParcel()
          }

          return true
        })
        .finally(() => this.emitter.emit('close-loader'))
    },
    postData(post) {
      if (post) {
        if (post.type === 'put' && this.$route.name.includes('Edit')) {
          this.emitter.emit('alert', {
            type: 'success',
            content: "L'essai a bien été mise à jour.",
          })
        } else {
          this.nextStep()
        }
      }
    },
    nextStep() {
      const params = { id: this.$route.params.id }
      this.$router.push({ name: 'essaiTestPlanAdd', params })
    },
  },
}
</script>
